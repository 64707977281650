const timeStamp=new Date().toLocaleTimeString('en-AU',{hour12:false,hour: "numeric",minute: "numeric"});
const hello=['Hi!','What’s up?','How are you?','How’s it going?','Namaste🙏🏻','Zimzalabim~']
const helloSeq=Math.floor(Math.random()*6)
const initialState = {
  stream:[
    ['msg',["in",hello[helloSeq],timeStamp]],
    ['msg',["in","Wish you well during this pandemic time. ",timeStamp]],
    ['item',['photo','apple.jpg']],
    ['msg',["in","I am better💪🏻 connected with you through Calendly now.",timeStamp]],
    ['article',['blog','Book a Time to Chat ☎️ / 🖥','No back and forth. Only being efficient.','2 Jul 2021','www','https://calendly.com/penn-from-melbourne','','Book Now']],
    ['item',['title','New Code']],
    //['article',['blog','OK, a New AWS DevOps Engineer is Coming','The Obsession of CI/CD is like the old days when everyone goes for an iPhone. Hopefully, getting an AWS DevOps certificate is not as complex as delivering an iOS app. This link is where I started on 15 June, 2021.','15 Jun 2021','www','devops.png','https://www.youtube.com/results?search_query=AWS+Certified+DevOps+Engineer']],
    ['article',['blog','My First iOS Application','Night and Day, a modern countdown list that helps you keep track of all important dates.','29 Mar 2021','www','https://apps.apple.com/au/app/night-and-day/id1554465670','nad.png','Download at App Store']],
    //['article',['blog','Prototyping My First iOS Application with ReactJS','Source code available on Github.','20 Jun 2020','/penn/night-and-day-reactjs']],
    ['item',['title','personal favorites']],
    ['article',['blog','Museum Guide Reimagined','A gaze-based museum guide system predicts user interests under 10 seconds through a gaze-based interface, powered by Javascript. ','6 Nov 2019','/penn/museum-guide-reimagined','gaze.gif']],
    ['article',['blog','Ideation for Silent Speech Interface','From sketches to a high-fidelity mockup video, this work presents a futuristic product design for giving out personal information to a public display.','15 Apr 2019','/penn/ideation-for-silent-speech-interface','ideation.jpg']],
    ['article',['blog','My Private Notes Service','I want a decent text editor and a self-destructing text sharing service. I designed and coded it on my own.','28 May 2020','/penn/loop','loop.jpg']],
    ['article',['blog','Restful APIs for a Mobile Application','APIs are organized according to database CRUD actions. The server responds to all requests with a JSON output.','24 Oct 2019','/penn/mobile-computing-api']],
    ['article',['blog','Unielection, an Accessible Election Experience','Have you ever tried to implement the very Aussie preferential voting in JavaScript? Here is my solution.','30 May 2019','/penn/unielection']],
    ['item',['title','articles']],
    ['article',['blog','How I Learned React','The simple answer is learning by shipping.','1 Mar 2020','/penn/how-I-learned-react']],
    ['article',['blog','Critical Reflection of the Heuristic Evaluation and Cognitive Walkthrough','My thoughts on the two techniques. #longPostAlert','2 Apr 2019','/penn/a-critical-reflection-of-the-heuristic-evaluation-and-cognitive-walkthrough']],
    ['item',['title','One More Thing']],
    ['msg',["in","Did you check out the latest news from Australia in the menu⤴️?",timeStamp]],
    ['item',['photo','mel.jpg']],
    ['msg',["in","Made with ❤️ in Melbourne"]],
  ],
  menu:['contact','google'],
};
export default function(state = initialState,action) {
  switch (action.type) {
    case "addCard": {
      const {cards} = action.payload;
      return {stream:[...state.stream,...cards],menu:[...state.menu]};
    }
    case "removeCard":{
      const {cards} = action.payload;
      let data=state.stream
      cards.map((value,index)=>{
        let i=data.indexOf(value)
        if(i>-1)data.splice(i,1)
        return i
      })
      return {stream:data,menu:[...state.menu]};
    }
    case "updateMenu": {
      const {newMenu} = action.payload;
      return {stream:[...state.stream],menu:newMenu};
    }
    case "resetAll": {
      return initialState
    }
    default:
      return state;
  }
}
