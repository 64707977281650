import React from 'react'
import { createStore, combineReducers } from 'redux'
import { Provider } from 'react-redux'

import * as reducers from './reducer'

const initialState = {}

const combinedReducers = combineReducers({ ...reducers})
export default ({element}) => {
  // const store = createStore(combinedReducers,initialState+window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  const store = createStore(combinedReducers,initialState)
  return <Provider store={store}>{element}</Provider>
}
