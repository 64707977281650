const initialState = {
  resolution:[0,0],
  msgPosition:0,
  returnToPosition:-1,
};

export default function(state = initialState,action) {
  switch (action.type) {
    case "updateResolution": {
      const {width,height} = action.payload;
      return {...state,resolution:[width,height]};
    }
    case "updateMsgPosition": {
      const {p} = action.payload;
      return {...state,msgPosition:p};
    }
    case "returnToPosition": {
      const {p} = action.payload;
      return {...state,returnToPosition:p};
    }
    default:
      return state;
  }
}
